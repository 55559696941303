import React from 'react'
import { useScroll } from '../Context/ScrollContext';

const Banner = () => {
    const { scrollToSection, bannerRef } = useScroll();


    return (
        <>
            <section ref={bannerRef} id='home'>
                <div className="relative">
                    {/* GIF Background */}
                    <img
                        src="/images/banner.gif"
                        alt="Gym Banner GIF"
                        className="absolute top-0 left-0 w-full h-full object-cover"
                    />

                    {/* Content */}
                    <div className="flex flex-col items-center justify-center min-h-screen text-white relative z-10">
                        <h1 className="text-center uppercase text-4xl text-shadow-lg sm:text-6xl md:text-6xl xl:text-8xl font-bold text-white mb-4">
                            easy with our<span className='text-orange-600'>{" "}gym</span>
                        </h1>
                        <p className="text-center uppercase text-xl text-shadow-lg sm:text-2xl md:text-4xl mb-4">
                            work harder, get stronger
                        </p>
                        <button
                            onClick={() => { scrollToSection("pricing") }}
                            className="uppercase bg-slate-600 text-orange-500 px-3 py-2 rounded-sm transition ease-in-out delay-150  hover:scale-125 duration-300">
                            become a member
                        </button>
                    </div>
                </div>

                {/* image based banner for a backup */}
                {/* <div className="bg-cover bg-center min-h-screen" style={{ backgroundImage: 'url(/images/banner.jpg)' }}>
                <div className="flex flex-col items-center justify-center min-h-screen text-white">
                    <h1 className="text-center uppercase text-4xl text-shadow-lg sm:text-6xl md:text-6xl xl:text-8xl font-bold text-orange-600 mb-4">
                        easy with our gym</h1>
                    <p className='text-center uppercase text-xl text-shadow-lg sm:text-2xl md:text-4xl mb-4'>work harder, get stronger</p>
                    <button className=' transition ease-in-out delay-150 bg-slate-600 text-orange-500 px-3 py-2 rounded-lg' >become a member</button>
                </div>
            </div> */}
            </section>
        </>
    )
}

export default Banner