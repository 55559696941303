import React from "react";
import { useScroll } from "../Context/ScrollContext";

const PricingCard = () => {

    const { pricingRef } = useScroll();

    const pricingPlans = [
        {
            title: "Montly Plan",
            price: "₹1500",
            // features: ["Admission", "10GB Storage", "Email Support"],
        },
        {
            title: "3-Month Plan",
            price: "₹3500",
            // features: ["5 Users", "50GB Storage", "Priority Support"],
        },
        {
            title: "6-Month Plan",
            price: "₹6000",
            // features: ["Unlimited Users", "1TB Storage", "24/7 Support"],
        },
        {
            title: "Yearly Plan",
            price: "₹8000",
            // features: ["Unlimited Users", "1TB Storage", "24/7 Support"],
        },
        {
            title: "Couples Plan - Yearly",
            price: "₹12000",
            // features: ["Unlimited Users", "1TB Storage", "24/7 Support"],
        },
    ];

    return (
        <>
            <section id="pricing" ref={pricingRef}>
                <div className="flex items-center justify-center mb-16">
                    <div className="max-w-7xl w-full px-6">
                        <div className='text-2xl font-bold text-center text-orange-600 uppercase'>
                            <span className='text-black mr-2'>Pricing</span>Plans
                        </div>
                        <div className="flex justify-center items-center mb-8">
                            <img
                                className='w-12 h-12 text-orange-600'
                                width="50px"
                                src="/images/wave.svg" alt="wave" />
                        </div>
                        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 text-center">
                            {pricingPlans.map((plan, index) => (
                                <div
                                    key={index}
                                    className="bg-white rounded-lg shadow-lg p-6 hover:scale-105 transition-transform duration-200"
                                >
                                    <h3 className="text-xl font-semibold text-gray-800 mb-4">
                                        {plan.title}
                                    </h3>
                                    <p className="text-4xl font-bold text-orange-600 mb-4">
                                        {plan.price}
                                    </p>
                                    <ul className="mb-6">
                                        {plan.features?.map((feature, i) => (
                                            <li key={i} className="text-gray-600 mb-2">
                                                ✅ {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <button className="w-full bg-orange-600 text-white py-2 px-4 rounded hover:bg-orange-400 transition ease-in-out duration-200">
                                        Choose Plan
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PricingCard;
