import React from 'react'
import Navbar from '../Components/Navbar'
import Banner from '../Components/Banner'
import Gmaps from '../Components/Gmaps'
import Programs from '../Components/Programs'
import SubBanners from '../Components/SubBanners'
import Footer from '../Components/Footer'
import PricingCard from '../Components/PricingCard'
import SignInModal from '../Components/SignInModal'
import { ScrollProvider } from '../Context/ScrollContext';
import { ModalProvider } from '../Context/ModalContext'

const HomePage = () => {
  return (
    <>
      <ModalProvider>
        <ScrollProvider>
          <Navbar />
          <Banner />
          <Programs />
          <SubBanners />
          <PricingCard />
          <Gmaps />
          <Footer />
          <SignInModal />
        </ScrollProvider >
      </ModalProvider>
    </>
  )
}

export default HomePage