import React from 'react'
import Navbar from './Navbar'

const NotFound = () => {
    return (
        <>
            <Navbar />
            <div className=' mt-16'>
                <div className="not-found-page ">
                    <h1>404 - Page Not Found</h1>
                    <p>Oops! The page you are looking for doesn't exist.</p>
                </div>
            </div>
        </>
    )
}

export default NotFound