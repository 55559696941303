import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useScroll } from '../Context/ScrollContext';
import { auth, onAuthStateChanged, provider, signInWithPopup } from "../Config/Firebase";

const Navbar = () => {
  const { scrollToSection } = useScroll();
  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const NavbarItems = ["Home", "Program", "Pricing", "Locate"];
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const bannerHeight = document.getElementById("home").offsetHeight; // Get banner height
      if (window.scrollY > bannerHeight) {
        setIsScrolled(true); // Change navbar style after crossing the banner
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setUser(user);
    } catch (error) {
      console.error("Error signing in: ", error);
    }
  };

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  return (
    <>
      <nav className="fixed top-0 left-0 w-full bg-white md:bg-transparent backdrop-blur-md shadow-lg z-50">
        <div className="container mx-auto px-4 flex justify-between items-center h-16">
          {/* Logo */}
          <div className="text-xl font-bold text-gray-800"
            onClick={() => {
              navigate("/")
            }}
          >
            <img className='w-14' src="/Logo.svg" alt="Logo" />
          </div>

          {/* Hamburger Menu (Mobile) */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-800 focus:outline-none"
            >
              <img src="" alt="" />
              <svg
                className={`w-6 h-6 transition-transform duration-300 ease-in-out ${isOpen ? "rotate-90" : "rotate-0"}`}
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d={
                    isOpen
                      ? "M6 18L18 6M6 6l12 12" // Close icon
                      : "M4 6h16M4 12h16m-7 6h7" // Hamburger icon
                  }
                />
              </svg>
            </button>
          </div>

          {/* Menu Links */}
          <div
            className={`${isOpen ? "block bg-white" : "hidden"
              } absolute top-16 left-0 w-full shadow-md md:static
               md:shadow-none md:flex md:items-center md:w-auto 
               transition-all duration-500 ease-in-out overflow-hidden`}
          >
            <ul className="flex flex-col md:flex-row md:space-x-8">
              {NavbarItems.map((page) => (
                <div key={page.toLowerCase()}>
                  <li>
                    <p className={`${isOpen ? "text-slate-700"
                      : isScrolled ? "text-slate-700" : "text-slate-50"}
                         block px-4 py-2
                          hover:text-orange-600`}
                      onClick={() => {
                        scrollToSection(page.toLowerCase())
                        setIsOpen(false)
                      }}
                    >
                      {page}
                    </p>
                  </li>
                </div>
              ))}
              <li>
                {!user ?
                  <Link
                    to="/"
                    className={`${isOpen ? "text-orange-600" : "bg-orange-600 text-slate-50"} 
                  block px-4 py-2  rounded-lg  hover:text-gray-800`}
                    onClick={handleLogin}
                  >
                    Sign In
                  </Link> :
                  <p className={`${isOpen ? "text-orange-600" : "bg-orange-600 text-slate-50"} 
                  block px-4 py-2  rounded-lg  hover:text-gray-800`} >
                    Hello, {user?.displayName}
                  </p>
                }
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar