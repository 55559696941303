import React from 'react'
import { useScroll } from '../Context/ScrollContext';

const Programs = () => {

    const { programRef } = useScroll();
    const programList = [{
        Title: "Basic Fitness",
        Description: "Start your fitness journey with our Basic Fitness program, focusing on strength, endurance, and flexibility to help you get fit and healthy."
    },
    {
        Title: "Weight Loss / Weight Gain Program",
        Description: "Reach your fitness goals with our program tailored for weight loss and gain, focusing on fat burning, muscle building, and balanced nutrition to achieve your ideal body composition."
    },
    {
        Title: "Bodybuilding Prep Training",
        Description: "Take your fitness to the next level with our Bodybuilding Prep Training. Designed for muscle definition and growth, this program focuses on strength training and advanced techniques for optimal results."
    },
    {
        Title: "Advanced Muscle Course",
        Description: "Push your limits with our Advanced Muscle Core Training, designed to target and strengthen core muscles for improved stability, strength, and overall athletic performance."
    },
    {
        Title: "Advanced Endurance Training",
        Description: "Elevate your endurance with a program that focuses on improving aerobic capacity, muscular endurance, and overall performance. Perfect for athletes and fitness enthusiasts looking to push their limits."
    },
    {
        Title: "Zumba Fitness",
        Description: "Get fit and have fun with Zumba Fitness! A high-energy workout that combines dance and aerobic movements to tone muscles, burn calories, and boost your mood."
    }
    ]


    return (
        <>
            <section id='program' ref={programRef}>
                <div className='mx-8 my-20 px-8'>
                    <div className='flex flex-col justify-center text-center'>
                        <div className='text-2xl font-bold text-orange-600 uppercase'>
                            <span className='text-black mr-2'>Choose</span>Program
                        </div>
                        <div className="flex justify-center items-center">
                            <img
                                className='w-12 h-12 text-orange-600'
                                width="50px"
                                src="/images/wave.svg" alt="wave" />
                        </div>
                        <div className='mt-4 text-slate-500'>
                            <p>Start Your Transformation with a Program that Fits Your Needs and Goals</p>
                        </div>
                    </div>
                    <div className='mt-16'>
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 text-center">
                            {programList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className="bg-white p-6 rounded-lg shadow-lg">
                                            <div className="flex items-center justify-start mb-4">
                                                <div className="flex justify-center bg-orange-500 p-4 w-full  rounded-lg">
                                                    <img className='w-12 h-12' src="/images/dumbell.svg" alt="" />
                                                </div>
                                            </div>
                                            <h3 className="text-xl font-semibold text-gray-800 mb-2">{item.Title}</h3>
                                            <p className="text-gray-600 mb-4">{item.Description}</p>
                                            {/* <a href="#" className="text-orange-600 hover:underline">Discover More</a> */}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Programs