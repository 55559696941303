import React, { useEffect, useState } from 'react'
import { useScroll } from '../Context/ScrollContext';

const Gmaps = () => {
    const { locateRef } = useScroll();
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <section id='locate' ref={locateRef}>
                <div className='flex flex-col justify-center items-center'>
                    <div className='text-2xl font-bold text-orange-600 uppercase'>
                        <span className='text-black mr-2'>Locate</span>Us
                    </div>
                    <div className="flex justify-center items-center">
                        <img
                            className='w-12 h-12 text-orange-600'
                            width="50px"
                            src="/images/wave.svg" alt="wave" />
                    </div>
                    <div className="px-4 py-4 mt-8">
                        <div className="relative overflow-hidden" >
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d979.0362937692339!2d76.90085669999999!3d11.0277327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85fc69badcc73%3A0x6ff4065bacdff341!2sThe%20muscle%20factory%20fitness%20centre!5e0!3m2!1sen!2sin!4v1732348203300!5m2!1sen!2sin"
                                width={width - 50} height="450"
                                style={{ border: "0" }}
                                allowFullScreen
                                loading="lazy"
                                title={`Google Map - ${process.env.REACT_APP_PROJECT_NAME}`}
                                referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gmaps