// ScrollContext.js
import React, { createContext, useContext, useRef } from 'react';

const ScrollContext = createContext();

export const useScroll = () => {
  return useContext(ScrollContext);
};

export const ScrollProvider = ({ children }) => {
  const bannerRef = useRef(null);
  const programRef = useRef(null);
  const pricingRef = useRef(null);
  const locateRef = useRef(null);

  const scrollToSection = (section) => {
    if (section === 'home' && bannerRef.current) {
      bannerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    if (section === 'pricing' && pricingRef.current) {
      pricingRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    if (section === 'program' && programRef.current) {
      programRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    if (section === 'locate' && locateRef.current) {
      locateRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <ScrollContext.Provider
      value={{ bannerRef, pricingRef, programRef, locateRef, scrollToSection }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
