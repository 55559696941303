import React, { useState } from 'react'
import { useModal } from '../Context/ModalContext';

const SignInModal = () => {

    const { isModalOpen, closeModal } = useModal();
    const [userData, setUserData] = useState({
        height: "",
        weight: "",
        goal: "",
        activity: ""
    })

    if (!isModalOpen) return null;

    const handleOnChange = (event) => {
        setUserData({
            ...userData,
            [event.target.name]: event.target.value
        })
    }

    const onSubmit = async () => {
        if (userData?.weight === "" || userData?.height === "" || userData?.activity === "" || userData?.goal === "") {
            return alert("Fill Form to proceed")
        } else {

            const isDownload = localStorage.getItem("isdownload");
            const downloded = localStorage.getItem("downloded");
            if (isDownload && downloded > 2) {
                return alert("You have exceeded the maximum diet limit. Please try again later.");
            } else {
                localStorage.setItem("isdownload", true);
                localStorage.setItem("downloded", downloded + 1);
                try {
                    let title = process.env.REACT_APP_PROJECT_NAME
                    let pdfUrl; // Adjust the path if needed
                    const link = document.createElement("a");

                    title = title.split(" ").join("_");

                    if (userData.goal === "weight loss" || userData.goal === "fat loss") {
                        pdfUrl = "/Weight_Loss.pdf";
                        title = `${title}_weight_loss`;
                    } else if (userData.goal === "muscle gain") {
                        pdfUrl = "/Weight_Gain.pdf";
                        title = `${title}_weight_gain`;
                    } else if (userData.goal === "maintain health") {
                        pdfUrl = "/Weight_Maintain.pdf";
                        title = `${title}_weight_maintain`;
                    }
                    link.href = pdfUrl;
                    link.download = `${title}.pdf`; // Specify the filename for download
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    closeModal();

                } catch (error) {
                    console.error("Error fetching diet chart:", error);
                }
            }
        }
    }

    return (
        <>
            <div className="flex items-center justify-center bg-gray-100">
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg w-96">
                        <div className="px-6 py-4 border-b">
                            <h2 className="text-lg font-bold">User's Information</h2>
                            <button
                                onClick={closeModal}
                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="p-6">
                            <form className="space-y-4">

                                {/* Height */}
                                <div>
                                    <label htmlFor="height" className="block text-sm font-medium text-gray-700">Height (cm)</label>
                                    <input
                                        onChange={handleOnChange}
                                        id="height"
                                        name='height'
                                        type="number"
                                        placeholder="Enter your height in cm"
                                        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>

                                {/* Weight */}
                                <div>
                                    <label htmlFor="weight" className="block text-sm font-medium text-gray-700">Weight (kg)</label>
                                    <input
                                        onChange={handleOnChange}
                                        id="weight"
                                        name='weight'
                                        type="number"
                                        placeholder="Enter your weight in kg"
                                        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>

                                {/* Goal */}
                                <div>
                                    <label htmlFor="goal" className="block text-sm font-medium text-gray-700">Choose Your Goal</label>
                                    <select
                                        id="goal"
                                        name='goal'
                                        onChange={handleOnChange}
                                        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option value="" disabled selected>Select your goal</option>
                                        <option value="weight loss">Weight Loss</option>
                                        <option value="fat loss">Fat Loss</option>
                                        <option value="muscle gain">Muscle Gain</option>
                                        <option value="maintain health">Maintain Health</option>
                                    </select>
                                </div>

                                <div>
                                    <label htmlFor="activity" className="block text-sm font-medium text-gray-700">Choose Your Activity</label>
                                    <select
                                        id="activity"
                                        name='activity'
                                        onChange={handleOnChange}
                                        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >
                                        <option value="" disabled selected>Select your activity</option>
                                        <option value="low">Low</option>
                                        <option value="moderate">Moderate</option>
                                        <option value="high">High</option>
                                        <option value="no workout">No Workout</option>
                                    </select>
                                </div>

                            </form>
                        </div>


                        <div className="flex justify-end px-6 py-4 border-t">
                            <button
                                onClick={onSubmit}
                                className="px-4 py-2 bg-orange-600 text-slate-100 border rounded hover:bg-slate-100 hover:text-orange-600 focus:outline-none"
                            >
                                Get Diet
                            </button>
                            <button
                                onClick={closeModal}
                                className="px-4 py-2 ml-4 text-gray-700 border rounded hover:bg-gray-100 focus:outline-none"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignInModal