import React from "react";

const Footer = () => {
    return (
        <footer className=" bg-slate-200 text-slate-500 py-4 text-center">
            <p className="text-sm">
                &copy; {new Date().getFullYear()} All Rights Reserved | Designed & Developed by <span className="font-bold">RHP</span>
            </p>
        </footer>
    );
};

export default Footer;
