import React, { useEffect, useState } from 'react'
import { auth, onAuthStateChanged } from '../Config/Firebase'
import { useModal } from '../Context/ModalContext';

const SubBanners = () => {

    const [user, setUser] = useState(null);
    const { openModal } = useModal();
    const handleClick = () => {
        if (!user) {
            alert("Sign In to Get A Free Diet")
        } else {
            openModal()
        }
    }

    useEffect(() => {
        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser); // User is logged in
            } else {
                setUser(null); // User is not logged in
            }
        });

        // Cleanup the listener on unmount
        return () => unsubscribe();
    }, []);

    return (
        <>
            <section id='sub_banner'>
                <div className='relative flex items-center justify-center h-[400px] bg-cover bg-center mb-20'
                    style={{ backgroundImage: "url(/images/sub_banner.png)" }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-25"></div>
                    <div className="relative z-10 text-center text-white px-6">
                        <h1 className="text-4xl font-bold uppercase">
                            Don't <span className="text-orange-500">Think</span>, Begin{" "}
                            <span className="text-orange-500">Today</span>!
                        </h1>
                        <p className="mt-4 text-lg">
                            Take charge of your fitness journey today and transform your life. Start now, because your goals won’t wait!
                        </p>
                        <button
                            className="mt-6 uppercase px-6 py-3 bg-orange-500 text-white font-semibold rounded-sm hover:bg-orange-600 transition ease-in-out delay-150  hover:scale-110 duration-300"
                            onClick={handleClick}
                        >
                            get a free diet plan
                        </button>
                    </div>
                </div>

            </section>
        </>
    )
}

export default SubBanners