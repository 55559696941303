import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from "./Pages/HomePage";
import NotFound from './Components/NotFound';

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            {/* Redirect /home to / */}
            <Route path="/home" element={<Navigate to="/" replace />} />
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
